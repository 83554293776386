var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-4"},[_vm._v("管理用の名前（自由に記載してください)"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"管理用の名前","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:255'),expression:"'required|max:255'"},{name:"model",rawName:"v-model.trim",value:(_vm.botForm.line_name),expression:"botForm.line_name",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","placeholder":"入力してください","name":"bot[line_name]","maxlength":"256"},domProps:{"value":(_vm.botForm.line_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.botForm, "line_name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row mt-4"},[_c('label',{staticClass:"col-4"},[_vm._v("LINE公式アカウントID"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"LINE公式アカウントID","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:255'),expression:"'required|max:255'"},{name:"model",rawName:"v-model.trim",value:(_vm.botForm.line_user_id),expression:"botForm.line_user_id",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","placeholder":"入力してください","name":"bot[line_user_id]","maxlength":"256"},domProps:{"value":(_vm.botForm.line_user_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.botForm, "line_user_id", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row mt-4"},[_c('label',{staticClass:"col-4"},[_vm._v("LIFF ID")]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"LIFF ID","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('max:255'),expression:"'max:255'"},{name:"model",rawName:"v-model.trim",value:(_vm.botForm.liff_id),expression:"botForm.liff_id",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","placeholder":"入力してください","name":"bot[liff_id]","maxlength":"256"},domProps:{"value":(_vm.botForm.liff_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.botForm, "liff_id", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row mt-4"},[_c('label',{staticClass:"col-4"},[_vm._v("PMS APIキー")]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":">PMS APIキー","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('max:255'),expression:"'max:255'"},{name:"model",rawName:"v-model.trim",value:(_vm.botForm.pms_api_key),expression:"botForm.pms_api_key",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","placeholder":"入力してください","name":"bot[pms_api_key]","maxlength":"256"},domProps:{"value":(_vm.botForm.pms_api_key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.botForm, "pms_api_key", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row mt-4"},[_c('label',{staticClass:"col-4"},[_vm._v("チャネルID"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"チャネルID","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:255'),expression:"'required|max:255'"},{name:"model",rawName:"v-model.trim",value:(_vm.botForm.channel_id),expression:"botForm.channel_id",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","placeholder":"入力してください","name":"bot[channel_id]","maxlength":"256"},domProps:{"value":(_vm.botForm.channel_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.botForm, "channel_id", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row mt-4"},[_c('label',{staticClass:"col-4"},[_vm._v("チャネルシークレット"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('ValidationProvider',{attrs:{"name":"チャネルシークレット","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:255'),expression:"'required|max:255'"},{name:"model",rawName:"v-model.trim",value:(_vm.botForm.channel_secret),expression:"botForm.channel_secret",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","placeholder":"入力してください","name":"bot[channel_secret]","maxlength":"256"},domProps:{"value":(_vm.botForm.channel_secret)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.botForm, "channel_secret", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row mt-4"},[_c('label',{staticClass:"col-4"},[_vm._v("Webhook URL")]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_c('input-with-copy',{attrs:{"text":_vm.webhookUrl}})],1)])])]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"btn btn-info fw-120",attrs:{"disabled":invalid},on:{"click":function($event){validate().then(_vm.onSubmit)}}},[_vm._v("保存")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }