var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.survey)?_c('div',{staticClass:"bg-white"},[(_vm.survey.banner_url)?_c('img',{staticClass:"banner mb-1",attrs:{"src":("" + (_vm.survey.banner_url))}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.survey.title))]),_vm._v(" "),_c('div',{staticClass:"font-12"},[_vm._v(_vm._s(_vm.survey.description))]),_vm._v(" "),(!_vm.verifyReservation && _vm.haveApiKey)?_c('div',{staticClass:"font-10 mt-2 precheckin-alert"},[_vm._v("\n    予約を照合できませんでした、予約データのご入力をお願いいたします。\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt-2"},[_c('div',[_c('survey-question-header',{attrs:{"question":_vm.survey.questions[0],"qnum":1}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"答え","rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.answers[1].answer),expression:"answers[1].answer",modifiers:{"trim":true}}],staticClass:"form-control mt-2",attrs:{"type":"text","name":("answers[" + (1) + "][answer]"),"placeholder":"入力してください","readonly":_vm.verifyReservation},domProps:{"value":(_vm.answers[1].answer)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.answers[1], "answer", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('error-message',{attrs:{"message":errors[0]}})]}}],null,false,3509583175)})],1),_vm._v(" "),_c('div',[_c('survey-question-header',{attrs:{"question":_vm.survey.questions[1],"qnum":2}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"電話番号","rules":"required|numeric|min:10|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.answers[2].answer),expression:"answers[2].answer",modifiers:{"trim":true}}],staticClass:"form-control mt-2",attrs:{"rows":"3","type":"number","name":("answers[" + (2) + "][answer]"),"placeholder":"電話番号を入力してください","readonly":_vm.verifyReservation},domProps:{"value":(_vm.answers[2].answer)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.answers[2], "answer", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('error-message',{attrs:{"message":errors[0]}})]}}],null,false,835635459)})],1),_vm._v(" "),_c('div',[_c('survey-question-header',{attrs:{"question":_vm.survey.questions[2],"qnum":3}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"チェックイン日","rules":{ required: _vm.dateRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group mt-2 position-relative"},[_c('datetime',{attrs:{"input-class":"form-control btn border border-light text-left","type":"date","phrases":{ ok: '確定', cancel: '閉じる' },"placeholder":"チェックイン日を選択してください","value-zone":"Asia/Tokyo","zone":"Asia/Tokyo","name":("answers[" + (3) + "][answer]"),"readonly":_vm.verifyReservation},model:{value:(_vm.answers[3].answer),callback:function ($$v) {_vm.$set(_vm.answers[3], "answer", $$v)},expression:"answers[3].answer"}}),_vm._v(" "),_c('i',{staticClass:"dripicons-chevron-down dropdown-icon"})],1),_vm._v(" "),_c('error-message',{attrs:{"message":errors[0]}})]}}],null,false,3377924567)})],1),_vm._v(" "),_c('div',[_c('survey-question-header',{attrs:{"question":_vm.survey.questions[3],"qnum":4}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"チェックアウト日","rules":{ required: _vm.dateRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group mt-2 position-relative"},[_c('datetime',{attrs:{"input-class":"form-control btn border border-light text-left","type":"date","phrases":{ ok: '確定', cancel: '閉じる' },"placeholder":"チェックアウト日を選択してください","value-zone":"Asia/Tokyo","zone":"Asia/Tokyo","name":("answers[" + (4) + "][answer]"),"readonly":_vm.verifyReservation},model:{value:(_vm.dateAnswer),callback:function ($$v) {_vm.dateAnswer=$$v},expression:"dateAnswer"}}),_vm._v(" "),_c('i',{staticClass:"dripicons-chevron-down dropdown-icon"})],1),_vm._v(" "),_c('error-message',{attrs:{"message":errors[0]}})]}}],null,false,732350079)})],1),_vm._v(" "),_c('div',[_c('survey-question-header',{attrs:{"question":_vm.survey.questions[4],"qnum":5}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"答え","rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.answers[5].answer),expression:"answers[5].answer",modifiers:{"trim":true}}],staticClass:"form-control mt-2",attrs:{"type":"text","name":("answers[" + (5) + "][answer]"),"placeholder":"入力してください"},domProps:{"value":(_vm.answers[5].answer)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.answers[5], "answer", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('error-message',{attrs:{"message":errors[0]}})]}}],null,false,3277151100)})],1),_vm._v(" "),_c('div',[_c('survey-question-header',{attrs:{"question":_vm.survey.questions[5],"qnum":6}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"答え","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.answers[6].answer),expression:"answers[6].answer"}],staticClass:"form-control w-100",attrs:{"name":("answers[" + (6) + "][answer]")},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.answers[6], "answer", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.survey.questions[5].content.options),function(option,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v("\n            "+_vm._s(option.value)+"\n          ")])}),0),_vm._v(" "),_c('error-message',{attrs:{"message":errors[0]}})]}}],null,false,2632993688)})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }