var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('form',{ref:"form",attrs:{"action":_vm.formAction,"method":"post","enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();validate().then(_vm.onSubmit)}}},[_c('input',{attrs:{"type":"hidden","name":"authenticity_token"},domProps:{"value":_vm.csrfToken}}),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"precheckin[friend_line_id]"},domProps:{"value":_vm.friendLineId}}),_vm._v(" "),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-bottom border-success"},[_c('h4',[_vm._v("事前チェックインフォーム")])]),_vm._v(" "),(!_vm.verifyReservation && _vm.haveApiKey && _vm.step1)?_c('div',{staticClass:"font-10 mt-2 precheckin-alert"},[_vm._v("\n        予約を照合できませんでした、予約データのご入力をお願いいたします。\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step1),expression:"step1"}]},[_c('ValidationObserver',{ref:"innerObs"},[_c('div',[_c('p',{staticClass:"w-100 mb-1"},[_c('span',{staticClass:"border-success question-title mr-2 font-weight-bold"},[_vm._v("Q1")]),_vm._v("お名前 "),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('ValidationProvider',{attrs:{"name":"お名前","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.precheckinFormData.name),expression:"precheckinFormData.name",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"precheckin[name]","placeholder":"お名前を入力してください","readonly":_vm.verifyReservation},domProps:{"value":(_vm.precheckinFormData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.precheckinFormData, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',[_c('p',{staticClass:"w-100 mb-1"},[_c('span',{staticClass:"border-success question-title mr-2 font-weight-bold"},[_vm._v("Q2")]),_vm._v("電話番号 / Phone Number "),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"w-100 text-muted text-sm my-1"},[_vm._v("ご予約に使った電話番号をご入力してください")]),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('ValidationProvider',{attrs:{"name":"電話番号","rules":"required|numeric|min:10|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.precheckinFormData.phone_number),expression:"precheckinFormData.phone_number",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"number","name":"precheckin[phone_number]","placeholder":"電話番号を入力してください","readonly":_vm.verifyReservation},domProps:{"value":(_vm.precheckinFormData.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.precheckinFormData, "phone_number", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',[_c('p',{staticClass:"w-100 mb-1"},[_c('span',{staticClass:"border-success question-title mr-2 font-weight-bold"},[_vm._v("Q3")]),_vm._v("チェックイン日 / Check-In Date "),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"w-100 text-muted text-sm my-1"},[_vm._v("ご予約のチェックイン日をご入力してください")]),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('ValidationProvider',{attrs:{"name":"チェックイン日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"input-class":"form-control","type":"date","phrases":{ ok: '確定', cancel: '閉じる' },"placeholder":"チェックイン日を選択してください","name":"precheckin[check_in_date]","value-zone":"Asia/Tokyo","zone":"Asia/Tokyo","readonly":_vm.verifyReservation},model:{value:(_vm.precheckinFormData.check_in_date),callback:function ($$v) {_vm.$set(_vm.precheckinFormData, "check_in_date", $$v)},expression:"precheckinFormData.check_in_date"}}),_vm._v(" "),_c('error-message',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_vm._v(" "),_c('div',[_c('p',{staticClass:"w-100 mb-1"},[_c('span',{staticClass:"border-success question-title mr-2 font-weight-bold"},[_vm._v("Q4")]),_vm._v("チェックアウト日 / Check-Out Date "),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"w-100 text-muted text-sm my-1"},[_vm._v("ご予約のチェックアウト日をご入力してください")]),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('ValidationProvider',{attrs:{"name":"チェックアウト日","rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"input-class":"form-control","type":"date","phrases":{ ok: '確定', cancel: '閉じる' },"placeholder":"チェックアウト日を選択してください","name":"precheckin[check_out_date]","value-zone":"Asia/Tokyo","zone":"Asia/Tokyo","readonly":_vm.verifyReservation},model:{value:(_vm.precheckinFormData.check_out_date),callback:function ($$v) {_vm.$set(_vm.precheckinFormData, "check_out_date", $$v)},expression:"precheckinFormData.check_out_date"}}),_vm._v(" "),_c('error-message',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_vm._v(" "),_c('div',[_c('p',{staticClass:"w-100 mb-1"},[_c('span',{staticClass:"border-success question-title mr-2 font-weight-bold"},[_vm._v("Q5")]),_vm._v("住所 / Address "),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('ValidationProvider',{attrs:{"name":"住所","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.precheckinFormData.address),expression:"precheckinFormData.address",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"precheckin[address]","placeholder":"住所を入力してください","maxlength":"256"},domProps:{"value":(_vm.precheckinFormData.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.precheckinFormData, "address", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',[_c('p',{staticClass:"w-100 mb-1"},[_c('span',{staticClass:"border-success question-title mr-2 font-weight-bold"},[_vm._v("Q6")]),_vm._v("性別 / Gender "),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('ValidationProvider',{attrs:{"name":"性別","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.precheckinFormData.gender),expression:"precheckinFormData.gender"}],staticClass:"form-control",attrs:{"name":"precheckin[gender]"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.precheckinFormData, "gender", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.genders),function(gender,index){return _c('option',{key:index,domProps:{"value":index}},[_vm._v("\n                      "+_vm._s(gender)+"\n                    ")])}),0),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step2),expression:"step2"}]},[_c('div',[_c('p',{staticClass:"w-100 mb-1"},[_c('span',{staticClass:"border-success question-title mr-2 font-weight-bold"},[_vm._v("Q7")]),_vm._v("生年月日 / Birthdate "),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('ValidationProvider',{attrs:{"name":"生年月日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime',{attrs:{"input-class":"form-control","type":"date","phrases":{ ok: '確定', cancel: '閉じる' },"placeholder":"チェックイン日を選択してください","name":"precheckin[birthdate]","value-zone":"Asia/Tokyo","zone":"Asia/Tokyo"},model:{value:(_vm.precheckinFormData.birthdate),callback:function ($$v) {_vm.$set(_vm.precheckinFormData, "birthdate", $$v)},expression:"precheckinFormData.birthdate"}}),_vm._v(" "),_c('error-message',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_vm._v(" "),_c('div',[_c('p',{staticClass:"w-100 mb-1"},[_c('span',{staticClass:"border-success question-title mr-2 font-weight-bold"},[_vm._v("Q7")]),_vm._v("ご利用シーン / Use Scene "),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('ValidationProvider',{attrs:{"name":"ご利用シーン","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.precheckinFormData.companion),expression:"precheckinFormData.companion"}],staticClass:"form-control",attrs:{"name":"precheckin[companion]"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.precheckinFormData, "companion", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.companionOptions),function(companion,key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v("\n                    "+_vm._s(companion)+"\n                  ")])}),0),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step1),expression:"step1"}]},[_c('div',{staticClass:"card-footer border-top pb-3 border-top-0"},[_c('button',{staticClass:"btn btn-success fw-120",attrs:{"type":"button"},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v("送信")])])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step2),expression:"step2"}]},[_c('div',{staticClass:"card-footer border-top pb-3 border-top-0"},[_c('button',{staticClass:"btn btn-success fw-120",attrs:{"type":"submit"}},[_vm._v("送信")])])]),_vm._v(" "),_c('loading-indicator',{attrs:{"loading":_vm.loading}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }